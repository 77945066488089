import { useEffect, useState } from 'react'

import { useProvider } from 'wagmi'
import { ethers } from 'ethers';

import NFTRepContract from '../../contracts/BrandRepNFT.json'


const BrandRepNftCard = ({contractAddress}) => {
  const [nftContractData, setNftContractData] = useState([])

  const provider = useProvider()

  useEffect(() => {

    const fetchNftContractData = async () => {
      const contract = new ethers.Contract(contractAddress, NFTRepContract.abi, provider);

      const name = await contract.name();
      const totalSupply = parseInt(await contract.totalSupply());

      setNftContractData({ name, totalSupply, contractAddress })
    }

    fetchNftContractData()
    
  }, []);


  return (
    <div className="mr-5 border-gray-500 border p-4 rounded-sm w-32 h-28 align-middle text-center">
      <div className='text-md font-semibold'>{nftContractData.name}</div>
      <div>{nftContractData.totalSupply} members</div>
      <div className='mt-3 flex align-middle justify-between'>
        <a href={`https://goerli.etherscan.io/address/${contractAddress}`} target='_blank'><img src='/etherscan.jpg' className="w-5 h-5" /></a>
        <a href={`https://testnets.opensea.io/assets/goerli/${contractAddress}`} target='_blank'><img src='/opensea.png' className="w-5 h-5" /></a>
        <a href={`https://goerli.rarible.com/collection/${contractAddress}/items`} target='_blank'><img src='/rarible.png' className="w-5 h-5" /></a>
      </div>
      
    </div>
  )
}

export default BrandRepNftCard;