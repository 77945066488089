import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useProvider, useAccount } from 'wagmi'
import { ethers, utils } from 'ethers';

import NFTRepContract from '../../contracts/BrandRepNFT.json'

import BrandRepNftCard from '../../components/brand-rep-nft-card/brand-rep-nft-card.component';
import TransactButton from '../../components/transact-button/transact-button.component';

const BrandNftRepShow = () => {
  const { contractAddress } = useParams();
  const provider = useProvider();
  const { data: accountData, isError: accountIsError, isLoading: accountIsLoading } = useAccount()

  const [tokens, setTokens] = useState([]);
  const [logs, setLogs] = useState([]);

  const fetchTokenHolders = async () => {
    const contract = new ethers.Contract(contractAddress, NFTRepContract.abi, provider);
    const totalSupply = parseInt(await contract.totalSupply());

    const tokenHolders = await Promise.all(Array.from({ length: totalSupply }, (_, i) => i).map(async (i) => {
      const tokenId = parseInt(await contract.tokenByIndex(i));
      const owner = await contract.ownerOf(tokenId);
      const ownerEns = await provider.lookupAddress(owner);
      const reputation = parseInt(await contract.getReputation(tokenId));
      
      const tokenUri = await contract.tokenURI(tokenId);
      const metadataJson = atob(tokenUri.substring(29));
      const metadata = JSON.parse(metadataJson);
      
      console.log("Metadata", metadata)

      return { tokenId, owner, ownerEns, reputation, tokenUri };
    }));

    setTokens(tokenHolders)

  }

  const fetchLogs = async () => {
    const contract = new ethers.Contract(contractAddress, NFTRepContract.abi, provider);


    const filterTransfers = contract.filters.Transfer(null);
    const filterAddRep = contract.filters.AddReputation(null);

    const transfers = await contract.queryFilter(filterTransfers, 7057325) //Block 7057325 is current block 14JUN)
    const reputationAdds = await contract.queryFilter(filterAddRep, 7057325) //Block 7057325 is current block 14JUN)

    let logs = [...transfers, ...reputationAdds] // concatenate arrays using spread operator
    logs.sort((a, b) => parseFloat(`${a.blockNumber}.${a.transactionIndex}`) - parseFloat(`${b.blockNumber}.${b.transactionIndex}`))

    setLogs(logs);
  }

  useEffect(() => {

    fetchTokenHolders();
    fetchLogs();


    const contract = new ethers.Contract(contractAddress, NFTRepContract.abi, provider);
    contract.on('Transfer', () => {
      console.log('Transfer event happened')
      fetchTokenHolders();
      fetchLogs();
    })

    contract.on('AddReputation', () => {
      console.log('AddReputation event happened')
      fetchTokenHolders();
      fetchLogs();
    })

  }, [])
  
  return (
    <div className="py-10">
      <header className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between">
            <BrandRepNftCard contractAddress={contractAddress} />
            <TransactButton contractAddress={contractAddress} contractAbi={NFTRepContract.abi} contractMethod="mint" contractArgs={[accountData.address]} buttonText='Mint now!' />
          
        </div>
        
        <hr className='mt-5' />
      </header>
      <main>
        <div className="max-w-7xl mx-auto sm:px-6 lg:px-8 mt-10">
        
          <h2 className='text-xl font-semibold leading-tight text-gray-900'>NFT Holders</h2>

          <table className="min-w-full divide-y divide-gray-300 mt-5">
            <thead className="bg-gray-50">
              <tr>
                <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">TokenID</th>
                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Owner</th>
                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Reputation</th>
                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Links</th>
                <th></th>
              </tr>
            </thead>
          

            <tbody className="divide-y divide-gray-200 bg-white">
              {tokens.map((token) => (
                <tr key={token.tokenId}>
                  <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                    {token.tokenId}
                  </td>
                  <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{token.ownerEns || token.owner}</td>
                  <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{token.reputation}</td>
                  <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                    <div className='flex'>
                      <a href={`https://testnets.opensea.io/assets/goerli/${contractAddress}/${token.tokenId}`} target='_blank'><img src='/opensea.png' className="w-5 h-5" /></a>
                      <a href={`https://goerli.rarible.com/token/${contractAddress.toLowerCase()}:${token.tokenId}`} target='_blank' className='ml-2'><img src='/rarible.png' className="w-5 h-5" /></a>
                    </div>
                  </td>
                  <td className="whitespace-nowrap px-3 py-4 text-sm flex justify-end">
                    <TransactButton contractAddress={contractAddress} contractAbi={NFTRepContract.abi} contractMethod="addReputation" contractArgs={[token.tokenId, 2]} buttonText='Add 2 rep' />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          <h2 className='text-xl font-semibold leading-tight text-gray-900 mt-10'>Logs</h2>
          <div className="mt-5 text-sm text-gray-700">
            {logs.map((log) => (
              <div key={log.transactionHash}>
                <span className='bg-gray-100 rounded-sm text-gray-700 text-xs px-1'>Block #{log.blockNumber}</span> <strong>{log.event}</strong>&nbsp;
                {log.event == 'Transfer' &&
                <span>from {log.args.from} to {log.args.to}</span>
                }
                {log.event == 'AddReputation' &&
                <span>{parseInt(log.args.reputation)} to tokenId {parseInt(log.args.tokenId)}. New total reputation: {parseInt(log.args.totalReputation)}</span>
                }
              </div>
            ))}
          </div>
        </div>

      </main>
    </div>
  )
}

export default BrandNftRepShow;