import { useState } from 'react';
import { Routes, Route } from 'react-router-dom';


const BankScanTest= () => {

  const [email, setEmail] = useState(null);

  const tinkRedirect = () => {
    const callbackUrl = process.env.REACT_APP_TINK_CALLBACK_URL;
    const tinkLinkUrl = `https://link.tink.com/1.0/transactions/connect-accounts/?client_id=9a8ce2b3408147929f2bc4f12a5f73fd&redirect_uri=${encodeURI(callbackUrl)}&market=DK&locale=da_DK`;
    window.location.href = tinkLinkUrl;
  }

  return (
    <div className="py-10">
      <header className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <div className="flex">
        {/* <img src="angry_chihuaha_base.svg" width="100" /> */}
        <div className=''>
          <h1 className="text-3xl font-bold leading-tight text-gray-900">Bank Scan</h1>
          <div className="mt-5">Scan 12 month transaction history for transactions with brands. No personal data is received from bank.</div>
        </div>
        
      </div>
      <hr className='mt-5' />
      </header>
      <main>
        <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">


          <div className='mt-5'>
            <button className='items-center mt-3 px-2.5 py-1.5 border border-transparent text-sm font-medium rounded shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:bg-indigo-300' onClick={tinkRedirect}>Authenticate Bank</button>
            
          </div>

        </div>
      </main>
    </div>
  )
}

export default BankScanTest;