import { Routes, Route } from 'react-router-dom';
import Navigation from './components/navigation/navigation.component';
import '@rainbow-me/rainbowkit/styles.css';

import {
  getDefaultWallets,
  RainbowKitProvider,
} from '@rainbow-me/rainbowkit';
import {
  chain,
  configureChains,
  createClient,
  WagmiConfig,
} from 'wagmi';

import { alchemyProvider } from 'wagmi/providers/alchemy';
import { publicProvider } from 'wagmi/providers/public';

import AngryChihuahas from './routes/angry-chihuahuas/angry-chihuahuas.component';
import BrandNftRep from './routes/brand-nft-rep/brand-nft-rep.component';
import BrandNftRepMetadataImage from './routes/brand-nft-rep-metadata-image/brand-nft-rep-metadata-image.component';
import BankScanTest from './routes/bank-scan-test/bank-scan-test.component';
import BankScanTestCallback from './routes/bank-scan-test/bank-scan-test-callback.component';

import Home from './routes/home/home.component';

import logo from './logo.svg';
import './App.css';

const { chains, provider } = configureChains(
  [chain.mainnet, chain.polygon,chain.rinkeby, chain.goerli, chain.polygonMumbai, chain.optimism, chain.arbitrum],
  [publicProvider()]
);

const { connectors } = getDefaultWallets({
  appName: 'Hyphen Playground',
  chains
});

const wagmiClient = createClient({
  autoConnect: true,
  connectors,
  provider
})


function App() {

  return (
    <WagmiConfig client={wagmiClient}>
      <RainbowKitProvider chains={chains}>
        <Routes>
          <Route path='/' element={<Navigation />}>
            <Route index element={<Home />}/>
            <Route path='angry-chihuahuas' element={<AngryChihuahas />}/>
            <Route path='nft-rep/*' element={<BrandNftRep />}/>
            <Route path='bank-test/' element={<BankScanTest />}/>
            <Route path='bank-test/callback/*' element={<BankScanTestCallback />}/>
          </Route>
          <Route path='nft-rep/metadata/image/:contractAddress/:tokenId' element={<BrandNftRepMetadataImage />} />
          
        </Routes>
      </RainbowKitProvider>
    </WagmiConfig>
  );
}

export default App;
