import { useEffect, useState } from 'react'
import { useAccount, useNetwork, useContractRead, useContractWrite, useProvider } from 'wagmi'
import BrandRepNFTFactory from '../../contracts/BrandRepNFTFactory.json'

import Alert from '../../components/alert/alert.component';
import BrandRepNftCard from '../../components/brand-rep-nft-card/brand-rep-nft-card.component';
import BrandRepNftCreateForm from '../../components/brand-rep-nft-create-form/brand-rep-nft-create-form.component';

import { ethers } from 'ethers';
import { Link } from 'react-router-dom';

const NETWORK_CHAIN_ID = 5; // Goerli

const FACTORY_CONTRACT = '0x5240688137D4315D0525E95719C4090853Ce6C3d';

const BrandNftRepOverview = () => {
  const [brandNftContracts, setBrandNftContracts] = useState([])

  const {
    activeChain
  } = useNetwork()
  const provider = useProvider()
  const { data: accountData, isError: accountIsError, isLoading: accountIsLoading } = useAccount()

  useEffect(() => {

    const fetchBrandNftContracts = async () => {
     
      const contract = new ethers.Contract(FACTORY_CONTRACT, BrandRepNFTFactory.abi, provider);
      console.log(contract)
      const filterFrom = contract.filters.BrandRepNftCreated();
      const logs = await contract.queryFilter(filterFrom, 7057325) //Block 7057325 is current block 14JUN
      const brandRepNftContractAddresses = logs.map(log => log.args[0])

      

      setBrandNftContracts(brandRepNftContractAddresses)
    }

    fetchBrandNftContracts()

  }, []);


  return (
    
    <div className="py-10">
      <header className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <div className="flex">
        {/* <img src="angry_chihuaha_base.svg" width="100" /> */}
        <div className=''>
          <h1 className="text-3xl font-bold leading-tight text-gray-900">Brand Reputation NFTs</h1>
          <div className="mt-5">An NFT experiment with NFTs representing membership and including a reputation score.</div>

          
        </div>
        
      </div>
      <hr className='mt-5' />
      </header>
      <main>
        <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
        { !accountData && <Alert title="Connect wallet!" description="Connect your wallet first - click the 'Connect' button in the top right corner." /> }

        { (activeChain?.id != NETWORK_CHAIN_ID) && <Alert title="Change network!" description={["Please change to Goerli Testnet. You can add the network from ", <a href='https://chainlist.org/'>chainlist.org</a>, " if you don't have it in your wallet."]} /> }
   
          <div className="px-4 py-8 sm:px-0">

            <h2 className="text-lg font-bold">Brand NFT Contracts</h2>
            <div className="flex mt-3">
              { brandNftContracts.map((contractAddress, index) => {
                return (
                  <Link to={`/nft-rep/${contractAddress}`} key={index}>
                    <BrandRepNftCard contractAddress={contractAddress} />
                  </Link>
                )
              })}
            </div>

            <h2 className="text-lg font-bold mt-10">Create new Brand NFT Contract</h2>
            <div className="grid grid-cols-1">
              <BrandRepNftCreateForm contractAddress={FACTORY_CONTRACT} />
            </div>  
            

            {/* 
            <div>
              <h2>Get your TestBrand membership NFT</h2>
            </div>

          
            <div>
              <h2>Add reputation to address</h2>
            </div> */}

            { accountIsLoading && <div>Loading...</div> }
            { accountIsError && <div>Error!</div> }


          </div>
        </div>
      </main>
    </div>
  )
}

export default BrandNftRepOverview;