import { useState } from 'react';
import { useAccount, useNetwork, useContractRead, useContractWrite, useProvider } from 'wagmi'
 
import BrandRepNFTFactory from '../../contracts/BrandRepNFTFactory.json'
import TransactButton from '../transact-button/transact-button.component';


const defaultFormFields = {
  name: '',
  symbol: '',
  metadataNamePrefix: '',
  metadataDescription: ''
}

const BrandRepNftCreateForm = ({contractAddress}) => {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [formFields, setFormFields] = useState(defaultFormFields)
  const resetFormFields = () => {
    setFormFields(defaultFormFields);
  }

  const setFormField = (field, value) => {
    setFormFields({
      ...formFields,
      [field]: value
    })
  }

  const submitSuccess = () => {
    resetFormFields()
  }

  return (
    <div className="w-4/12">
      <div>
        <label htmlFor="name" className="block text-sm font-medium text-gray-700">
          Token Name
        </label>
        <div className="mt-1">
          <input
            id="name"
            name="name"
            type="text"
            className="sshadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
            value={formFields.name}
            placeholder="MyBrand Membership"
            onChange={(e) => setFormField('name', e.target.value)}
          />
        </div>
      </div>
      <div className='mt-3'>
        <label htmlFor="symbol" className="block text-sm font-medium text-gray-700">
          Token Symbol
        </label>
        <div className="mt-1">
          <input
            id="symbol"
            name="symbol"
            type="text"
            className="sshadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
            value={formFields.symbol}
            placeholder="MYB-MEMBERSHIP"
            onChange={(e) => setFormField('symbol', e.target.value)}
          />
        </div>
      </div>
      <div className='mt-3'>
        <label htmlFor="metadataNamePrefix" className="block text-sm font-medium text-gray-700">
          Metadata Name Prefix
        </label>
        <div className="mt-1">
          <input
            id="metadataNamePrefix"
            name="metadataNamePrefix"
            type="text"
            className="sshadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
            value={formFields.metadataNamePrefix}
            placeholder="MyBrand Membership"
            onChange={(e) => setFormField('metadataNamePrefix', e.target.value)}
          />
        </div>
        <div className='text-xs text-gray-700'>Each NFT will be named <strong>{formFields.metadataNamePrefix} #1</strong>, <strong>{formFields.metadataNamePrefix} #2</strong> and so on...</div>
      </div>
      <div className='mt-3'>
        <label htmlFor="metadataDescription" className="block text-sm font-medium text-gray-700">
          Metadata Description
        </label>
        <div className="mt-1">
          <textarea
            id="metadataDescription"
            name="metadataDescription"
            rows={3}
            className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border border-gray-300 rounded-md"
            value={formFields.metadataDescription}
            placeholder="This is a membership token for MyBrand."
            onChange={(e) => setFormField('metadataDescription', e.target.value)}
          />
        </div>

      </div>

      <TransactButton contractAddress={contractAddress} contractAbi={BrandRepNFTFactory.abi} contractMethod='createBrandRepNft' contractArgs={[ formFields.name, formFields.symbol, formFields.metadataNamePrefix, formFields.metadataDescription ]} buttonText='Create Contract' successCallback={submitSuccess} />

    </div>
  )
}

export default BrandRepNftCreateForm;