import { useEffect, useState } from 'react'
import { useAccount, useNetwork, useContractRead, useContractWrite, useWaitForTransaction } from 'wagmi'
import AngryChihuahuaNFT from '../../contracts/AngryChihuahuaNFT.json'

import Alert from '../../components/alert/alert.component';

const CONTRACT_ADDRESS_POLYGON_MUMBAI = '0x2388b50018175f613a0C2C7cB4Da38AD0402cBaa';
const MUMBAI_CHAIN_ID = 80001;

const AngryChihuahas = () => {
  const [isMinting, setIsMinting] = useState(false)
  const [mintTxn, setMintTxn] = useState(null)
  const [mintedTokenId, setMintedTokenId] = useState(null)

  const {
    activeChain
  } = useNetwork()

  const { data: accountData, isError: accountIsError, isLoading: accountIsLoading } = useAccount()

  const { data: mintData, isError: mintIsError, isLoading: mintIsLoading, writeAsync: mintWriteAsync } = useContractWrite(
    {
      addressOrName: CONTRACT_ADDRESS_POLYGON_MUMBAI,
      contractInterface: AngryChihuahuaNFT.abi
    },
    'mintNFT'
  )

  const waitForTransaction = useWaitForTransaction({
    hash: mintTxn,
    onSettled(data) {
      setIsMinting(false)
    }
  },{
    onSuccess(data) {
      console.log("Minted token id:", data.returnValue)
    }
  })

  const mintNFT = async () => {
    setIsMinting(true)
    setMintTxn(null)
    setMintedTokenId(null)
    const mintTransaction = await mintWriteAsync();
    setMintTxn(mintTransaction.hash)
  }

  const { data: balanceData, isError: balanceIsError, isLoading: balanceIsLoading } = useContractRead(
    {
      addressOrName: CONTRACT_ADDRESS_POLYGON_MUMBAI,
      contractInterface: AngryChihuahuaNFT.abi
    },
    'balanceOf', { args: '0xB2B1520EE299165622ec7C1A560b102546047cD8' }
  )

  useEffect(() => {
    if (waitForTransaction.data?.logs[0]) {
      console.log("logs", waitForTransaction.data?.logs[0])
      setMintedTokenId(parseInt(waitForTransaction.data?.logs[0].topics[3], 16));
    }
    
  }, [waitForTransaction.data, waitForTransaction.isLoading, waitForTransaction.isError ])


  const parseBalance = (balanceData) => {
    return parseInt(balanceData);
  }

  return (
    <div className="py-10">
      <header className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <div className="flex">
        <img src="angry_chihuaha_base.svg" width="100" />
        <div className='ml-10'>
          <h1 className="text-3xl font-bold leading-tight text-gray-900">Angry Chihuahuas</h1>
          <div className="mt-5">An NFT experiment on Polygon Mumbai with 100 Angry Chihuahas available for minting! Check it on <a href="https://testnets.opensea.io/collection/angry-chihuahua-v3" target="_blank" className='underline'>OpenSea</a>.</div>

          
        </div>
        
      </div>
      <hr className='mt-5' />
      </header>
      <main>
        <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
        { !accountData && <Alert title="Connect wallet!" description="Connect your wallet first - click the 'Connect' button in the top right corner." /> }
        { (activeChain?.id != MUMBAI_CHAIN_ID) && <Alert title="Change network!" description={["Please change to Polygon Mumbai. You can add the network from ", <a href='https://chainlist.org/'>chainlist.org</a>, " if you don't have it in your wallet."]} /> }
   
          <div className="px-4 py-8 sm:px-0">
          

            { accountIsLoading && <div>Loading...</div> }
            { accountIsError && <div>Error!</div> }
            { balanceIsLoading && <div>Loading...</div> }
            { accountData && balanceIsError   && <div>Error retrieving balance from contract!</div> }


            {(activeChain?.id === MUMBAI_CHAIN_ID && accountData)  && 
            <div>
              <h2 className='text-xl font-semibold'>Ready to MINT!</h2>

              <div>You have <strong>{parseBalance(balanceData)}</strong> Angry Chihuahas! Why not mint a new one?</div>

              <div className='text-sm text-gray-500 mt-2'>Psst... You need MATIC on the Polygon Mumbai testnet to mint. Get some <a href="https://mumbaifaucet.com/" target="_blank" className="underline">here</a> or <a href="https://faucet.polygon.technology/" target="_blank" className="underline">here</a>.</div>

              <div className='mt-10'>
                { !isMinting && <button className="inline-flex items-center mt-5 px-2.5 py-1.5 border border-transparent text-xs font-medium rounded shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500" onClick={mintNFT}>Mint Angry Chihuahua!</button> }
                { isMinting && <div>Minting, please wait...</div> }
                { (mintTxn && mintedTokenId) && <a href={`https://testnets.opensea.io/assets/mumbai/${mintTxn}/${mintedTokenId}`} target="_blank">Success! Click to view on OpenSea.</a> }
              </div>
            </div>
            }
          </div>
        </div>
      </main>
    </div>
  )
}

export default AngryChihuahas;