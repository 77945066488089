import { Link } from "react-router-dom"

const Home = () => {
  return (
    <div className="max-w-7xl mx-auto sm:px-6 lg:px-8 text-center ">
      <div className="px-4 py-12 sm:px-0">
        <h1 className="text-3xl font-bold">Welcome to the Hyphen Playground</h1>

        <div className="h-64 w-64 mt-10 flex items-center rounded-full mx-auto  from-blue-400 via-teal-300 to-purple-500 animate-text text-lg bg-gradient-to-r bg-clip-content  text-transparent ">
          <div className="rounded-full bg-white flex items-center mx-auto" style={{width: '95%', height: '95%'}}>
            <img src="hyphen_h.svg" className="mx-auto" style={{width: '70%', height: '70%'}}/>
          </div>
          {/* from-blue-400 via-teal-300 to-purple-500 */}
        </div>
        <p className="mt-10 text-gray-800">Check our latest project, <Link to="/angry-chihuahuas" className="underline">Angry Chihuahuas</Link></p>
      </div>
    </div>
  )
}

export default Home