import { useEffect, useState } from 'react';
import { useAccount, useContractWrite, useWaitForTransaction } from 'wagmi'

const TransactButton = ({contractAddress, contractAbi, contractMethod, contractArgs, buttonText, successCallback}) => {
  const [isMinting, setIsMinting] = useState(false)
  const [mintTxn, setMintTxn] = useState(null)
  const [mintedTokenId, setMintedTokenId] = useState(null)
  
  const { data: accountData, isError: accountIsError, isLoading: accountIsLoading } = useAccount()
  
  const { data: mintData, isError: mintIsError, error: mintError, isLoading: mintIsLoading, writeAsync: mintWriteAsync } = useContractWrite(
    {
      addressOrName: contractAddress,
      contractInterface: contractAbi
    },
    contractMethod,
    {
      args: contractArgs,
      onError: (error) => {
        setIsMinting(false)
        console.log(error)
        alert(error.error.message)
      }
    },
  )

  const waitForTransaction = useWaitForTransaction({
    hash: mintTxn,
    onSettled(data) {
      setIsMinting(false)
    }
  },{
    onSuccess(data) {
      successCallback()
    }
  })

  const mintNFT = async () => {
    setIsMinting(true)
    setMintTxn(null)
    setMintedTokenId(null)
    console.log("Mint", contractAddress, contractMethod, accountData.address)
    const mintTransaction = await mintWriteAsync();
    setMintTxn(mintTransaction.hash)
  }


  useEffect(() => {
    
    if (waitForTransaction.data?.logs[0]) {
      console.log("logs", waitForTransaction.data?.logs[0])
      setMintedTokenId(parseInt(waitForTransaction.data?.logs[0].topics[3], 16));
    }
    
  }, [waitForTransaction.data, waitForTransaction.isLoading, waitForTransaction.isError ])


  return (
    <div>
    {
      <button className="inline-flex items-center px-2.5 py-1.5 border border-transparent text-sm font-medium rounded shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:bg-indigo-300" onClick={mintNFT} disabled={isMinting}>
      {!isMinting && <span>{buttonText}</span>}
      {isMinting && mintIsLoading && <span>Sending...</span>}
      {isMinting && !mintIsLoading && <span>Confirming...</span>}
      </button>
    }
    </div>
  )

}

export default TransactButton;