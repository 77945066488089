import { Routes, Route } from 'react-router-dom';

import BrandNftRepOverview from '../brand-nft-rep-overview/brand-nft-rep-overview.component.jsx'
import BrandNftRepShow from '../brand-nft-rep-show/brand-nft-rep-show.component.jsx'

const BrandNftRep = () => {

  return (
    <Routes>
      <Route index element={<BrandNftRepOverview />}/>
      <Route path=':contractAddress' element={<BrandNftRepShow />}/>
    </Routes>
  )
}

export default BrandNftRep;