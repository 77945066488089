import { useParams } from 'react-router-dom';
import { ethers } from 'ethers';
import { useEffect, useState } from 'react';

import NFTRepContract from '../../contracts/BrandRepNFT.json'

const BrandNftRepMetadataImage = () => {
  const { contractAddress, tokenId } = useParams();
  const [tokenData, setTokenData] = useState(null);

  const fetchTokenDetails = async () => {
    const provider = new ethers.providers.AlchemyProvider("goerli", "88Kb_LiOr_I1cJocw2X7wzRb8FjzqNVE");
    const contract = new ethers.Contract(contractAddress, NFTRepContract.abi, provider);
    console.log("TokenId", tokenId)

    const reputation = parseInt(await contract.getReputation(tokenId));

    const tokenUri = await contract.tokenURI(tokenId);
    const metadataJson = atob(tokenUri.substring(29));
    const metadata = JSON.parse(metadataJson);

    setTokenData({metadata, reputation});
  }

  useEffect(() => {
    fetchTokenDetails();
  }, [])

  return (
    <div className='w-[350px] h-[350px]'>
      
      { tokenData && 
        <div className="bg-slate-200 text-center h-full grid grid-cols-1">
          <div className='font-semibold pt-10 text-xl'>{tokenData.metadata.name}</div>
          <div className='mt-10'>YOUR LOGO HERE</div>
          <div className='flex justify-center pb-10 self-end'>
            <div className='mr-10'>
              <div className='text-xs text-slate-700'>LEVEL</div>
              <div className='text-lg'>BRONZE</div>
            </div>
          
            <div className=''>
              <div className='text-xs text-slate-700'>REPUTATION</div>
              <div className='text-lg'>{tokenData.reputation}</div>
            </div>
          </div>
          <span id="HCTIReadyNow"></span>
        </div>
      }
    </div>
  )
}



export default BrandNftRepMetadataImage;