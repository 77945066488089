import { useState } from 'react';
import { Routes, Route } from 'react-router-dom';

const BANKFETCH_ENDPOINT = process.env.REACT_APP_BANK_TEST_DATAFETCH_FUNCTION_URL;

const BankScanTestCallback= () => {

  const [message, setMessage] = useState(null);

  useState(() => {
    const params = new URLSearchParams(window.location.search);

    const error = params.get("error");

    if (error) {
      const message = params.get("message");

      setMessage(message);

      // Error, a localized user-displayable error message is available in `message`
    } else {
      const code = params.get("code");

      fetch(`${BANKFETCH_ENDPOINT}?code=${code}`)

      if (code) {
        setMessage('Succefully connected, thank you!')
      } else {
        setMessage('No code provided')
      }

      // Success, continue by sending `code` or any other success response parameters to you backend service
      // Refer to the the Tink Link API Reference for the respective product you are using.
    }
  }, [])

  return (
    <div className="py-10">
      <header className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <div className="flex">
        {/* <img src="angry_chihuaha_base.svg" width="100" /> */}
        <div className=''>
          <h1 className="text-3xl font-bold leading-tight text-gray-900">Bank Scan</h1>
          <div className="mt-5">Scan 12 month transaction history for transactions with brands. No personal data is received from bank.</div>
        </div>
        
      </div>
      <hr className='mt-5' />
      </header>
      <main>
        <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">


          <div className='mt-5'>
            <p>{message}</p>
          </div>

        </div>
      </main>
    </div>
  )
}

export default BankScanTestCallback;